import React, { Component, Fragment } from 'react';
import { func, array, object, shape, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pubWithSale, Event } from 'lib/events';
import { tokenizeCardForCustomer } from 'lib/braintree';
import { CreditCardSwiper } from 'lib/keyboard';
import { isValidExpirationDate } from 'lib/validation';
import { get } from 'lib/network';
import { apiUrl } from 'lib/url';
import {
  getSaleByNumber,
  getSaleItemsBeingShipped,
  getSaleStatus,
  Status,
} from 'reducers/sales';
import { getCanAssociateApplyCreditToSale } from 'reducers/associate';
import { getStorefrontById } from 'reducers/storefronts';
import ConfirmationDialog from 'components/shared/ConfirmationDialog';
import {
  createCreditCard,
  disableCreditCard,
  addCreditCardToSale,
  applyDiscountCode,
  toggleApplyCredit,
  toggleRetailDiscount,
  applyNewCredit,
  removePaymentFromSale,
  applyCashToSale,
  fetchCashFloats,
  fetchOrderPreview,
} from 'actions/asynchronous';
import {
  startRequest,
  finishRequest,
  showNotification,
  showDialog,
  hideDialog,
  grantFreeShipping,
} from 'actions/synchronous';
import { NotificationTypes } from 'reducers/notifications';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import CreditCard from 'components/shared/CreditCard';
import NoItemsMessage from 'components/shared/NoItemsMessage';
import Scrollable from 'components/shared/Scrollable';
import StripeCreditCardForm from 'components/sales/StripeCreditCardForm';
import StripePaymentWrapper from 'components/sales/StripePaymentWrapper';
import DiscountCodeForm from 'components/sales/DiscountCodeForm';
import ExpandableContent from 'components/shared/ExpandableContent';
import CreditToggle from 'components/sales/CreditToggle';
import CreditForm from 'components/sales/CreditForm';
import Button, { ButtonStyles } from 'components/shared/Button';
import Tabs from 'components/shared/Tabs';
import NumberKeyPad from 'components/shared/NumberKeyPad';
import { shouldRefetchOrderPreview } from 'lib/sale';
import CreditCardForm from 'components/sales/CreditCardForm';

class AddPaymentToSale extends Component {
  static propTypes = {
    createCreditCard: func.isRequired,
    addCreditCardToSale: func.isRequired,
    disableCreditCard: func.isRequired,
    sale: shape({
      payment_method: object,
      customer: shape({
        credit_cards: array.isRequired,
      }).isRequired,
    }).isRequired,
    showDialog: func.isRequired,
    hideDialog: func.isRequired,
    fetchCashFloats: func.isRequired,
    orderTotal: string,
    applyCashToSale: func.isRequired,
    acceptsCash: bool,
    cashFloat: object,
    saleItemsBeingShipped: array,
    onContinue: func,
  };

  static defaultProps = {
    cashFloat: {},
    saleItemsBeingShipped: [],
  };

  state = {
    reasonsToGrantCredit: [],
    forceManualEntry: false,
    freeShippingGranted: false,
    activePaymentMethodTab: 'add-card',
    activeCreditsTab: 'existing-credit',
    cashValue: '',
    cashError: '',
    isLoading: false,
    stripeError: false,
  };

  constructor(props) {
    super(props);
    this.swiper = new CreditCardSwiper({
      callback: creditCard => {
        if (
          creditCard &&
          (!creditCard.fullName ||
            !creditCard.number ||
            !creditCard.expirationDate)
        ) {
          return this.setState({
            forceManualEntry: true,
          });
        }

        this.handleCreateCreditCard(creditCard).then(
          response => {
            pubWithSale(Event.SalePayment.SWIPE_CARD, {
              outcome: 'success',
            });
          },
          error => {
            pubWithSale(Event.SalePayment.SWIPE_CARD, {
              outcome: 'failure',
            });
          },
        );
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (shouldRefetchOrderPreview(prevProps.sale, this.props.sale)) {
      this.fetchOrderPreview();
    }
  }

  componentDidMount() {
    pubWithSale(Event.SalePayment.PAGE_VIEW);
    this.swiper.listen();
    this.fetchCreditReasons();
    this.fetchOrderPreview();
    if (this.props.acceptsCash) {
      this.props.fetchCashFloats();
    }
  }

  componentWillUnmount() {
    this.swiper.stopListening();
  }

  fetchCreditReasons = async () => {
    const { response, responseJson } = await get(apiUrl('credit_reasons'), {
      for: 'retail',
    });

    if (response.ok) {
      this.setState({ reasonsToGrantCredit: responseJson.available_reasons });
    }
  };

  fetchOrderPreview() {
    this.setState({ isLoading: true });
    this.props
      .fetchOrderPreview()
      .then(() => this.setState({ isLoading: false }));
  }

  isPaymentMethodTabSelected = value => {
    return this.state.activePaymentMethodTab === value;
  };

  isCreditsTabSelected = value => {
    return this.state.activeCreditsTab === value;
  };

  handleClickKeyPadNumber = value => {
    this.setState(prevState => {
      let newCashValue;

      if (value === '<') {
        newCashValue = prevState.cashValue.slice(0, -1);
      } else {
        newCashValue = prevState.cashValue + value;
      }

      return { cashValue: newCashValue };
    });
  };

  handleToggleCredit = () => {
    if (this.props.sale.apply_credits) {
      pubWithSale(Event.SalePayment.DESELECT_EXISTING_CREDIT);
    } else {
      pubWithSale(Event.SalePayment.SELECT_EXISTING_CREDIT);
    }

    this.props.toggleApplyCredit();
  };

  handleToggleRetailDiscount = async () => {
    this.props.toggleRetailDiscount();
  };

  handleApplyNewCredit = ({ amount, reason }) => {
    return this.props.applyNewCredit(amount, reason).then(() => {
      if (!this.props.sale.apply_credits) {
        return this.handleToggleCredit();
      }
    });
  };

  handleApplyCash = () => {
    // check for invalid currency formats:
    // 1. more than one decimal place
    // 2. more than 2 digits after decimal
    const valueArray = this.state.cashValue.split('.');
    if (
      valueArray.length > 2 ||
      (valueArray.length === 2 && valueArray[1].length > 2)
    ) {
      this.setState({ cashError: 'Invalid dollar amount' });
      return;
    }

    // check to see that the input value exceeds the total sale amount
    if (
      parseFloat(this.state.cashValue) >=
      parseFloat(this.props.orderTotal.replace(/[^\d.]/, ''))
    ) {
      this.props.applyCashToSale(
        `$${parseFloat(this.state.cashValue).toFixed(2)}`,
      );
      this.setState({ cashError: '' });
    } else {
      this.setState({ cashError: 'Sale amount exceeds cash amount' });
    }
  };

  handleAddCreditCardToSale = creditCard => {
    if (
      this.props.sale.payment_method &&
      creditCard.id === this.props.sale.payment_method.id
    ) {
      // this card was already selected - a second tap indicates we want to deselect it.
      this.props.removePaymentFromSale();
    } else {
      this.props.addCreditCardToSale(creditCard).then(
        response => {
          pubWithSale(Event.SalePayment.SELECT_EXISTING_CARD, {
            outcome: 'success',
          });
        },
        error => {
          pubWithSale(Event.SalePayment.SELECT_EXISTING_CARD, {
            outcome: 'failure',
          });
        },
      );
    }
  };

  handleCreateStripeCreditCard = paymentMethodId => {
    if (!paymentMethodId) {
      this.props.showErrorNotification(
        'An error occurred. Try swiping the card again, or entering it manually.',
      );
    }

    this.props.startRequest();
    this.props
      .createCreditCard({
        nonce: paymentMethodId,
        customerEmail: this.props.sale.customer.email,
        paymentType: 'StripeWebCreditCard',
      })
      .then(() => this.props.finishRequest());
  };

  handleCreateCreditCard = creditCard => {
    // For some reason, braintree does not validate credit card expiration. Alas…

    if (!creditCard) {
      this.props.showErrorNotification(
        'An error occurred. Try swiping the card again, or entering it manually.',
      );
      return Promise.reject();
    }

    if (!isValidExpirationDate(creditCard.expirationDate)) {
      this.props.showErrorNotification('This credit card is expired');
      return Promise.reject();
    } else {
      this.props.startRequest();
    }

    return tokenizeCardForCustomer(this.props.sale.customer, creditCard).then(
      nonce => {
        return this.props
          .createCreditCard({
            nonce,
            customerEmail: this.props.sale.customer.email,
            paymentType: 'BraintreeCreditCard',
          })
          .then(() => this.props.finishRequest());
      },
      error => {
        this.props.finishRequest();
        if (error.details.originalError.fieldErrors) {
          const creditCardError = error.details.originalError.fieldErrors[0];
          this.props.showErrorNotification(
            creditCardError.fieldErrors[0].message,
          );
        }

        return Promise.reject();
      },
    );
  };

  handleGrantFreeShipping = () => {
    this.props.showDialog(
      <ConfirmationDialog
        title="Grant free shipping?"
        question="Are you sure you want to grant this customer free shipping?"
        onYes={() => {
          pubWithSale(Event.SalePayment.GRANT_FREE_SHIPPING);
          this.props.grantFreeShipping();
          this.props.hideDialog();
          this.setState({ freeShippingGranted: true });
        }}
        onNo={() => {
          this.props.hideDialog();
        }}
        buttonStyle={ButtonStyles.TERTIARY}
      />,
    );
  };

  handleRemoveCreditCard = creditCard => {
    this.props.showDialog(
      <ConfirmationDialog
        buttonStyle={ButtonStyles.TERTIARY}
        title="Remove Card?"
        question="Are you sure you want to remove this payment method?"
        onYes={() => {
          this.props.disableCreditCard(creditCard);
          //NOTE: if this credit card is the current payment method chosen for
          //the sale, we want to remove it from the sale
          if (
            this.props.sale.payment_method &&
            creditCard.id === this.props.sale.payment_method.id
          ) {
            this.props.addCreditCardToSale(null);
          }
          this.props.hideDialog();
        }}
        onNo={() => {
          this.props.hideDialog();
        }}
      />,
    );
  };

  handleRemoveTemporaryPaymentMethod = () => {
    this.props.showDialog(
      <ConfirmationDialog
        buttonStyle={ButtonStyles.TERTIARY}
        title="Remove Card?"
        question="Are you sure you want to remove this payment method?"
        onYes={() => {
          this.props.addCreditCardToSale(null);
          this.props.hideDialog();
        }}
        onNo={() => {
          this.props.hideDialog();
        }}
      />,
    );
  };

  handleApplyDiscount = discountCode => {
    pubWithSale(Event.SalePayment.APPLY_CODE);

    return this.props.applyDiscountCode(
      discountCode,
      this.props.sale.customer.email,
    );
  };

  handleClickPaymentMethodTab = tabValue => {
    this.setState({ activePaymentMethodTab: tabValue });
  };

  handleClickCreditsTab = tabValue => {
    this.setState({ activeCreditsTab: tabValue });
  };

  renderStripeErrorMessage() {
    return (
      <div className="ma2 pa2 ba b--gray-8 red-5">
        Stripe failed to load. You can manually add a payment method below, or
        click{' '}
        <Link
          onClick={e => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          here
        </Link>{' '}
        to refresh the page and try again.
      </div>
    );
  }

  renderFallbackCreditCardForm() {
    return (
      <CreditCardForm
        onSubmit={creditCard => {
          return this.handleCreateCreditCard(creditCard).then(
            response => {
              pubWithSale(Event.SalePayment.ADD_MANUAL_PAYMENT, {
                outcome: 'success',
              });
            },
            error => {
              pubWithSale(Event.SalePayment.ADD_MANUAL_PAYMENT, {
                outcome: 'failure',
              });
            },
          );
        }}
      />
    );
  }

  renderStripeCreditCardForm() {
    return (
      <div>
        {this.state.stripeError && this.renderFallbackCreditCardForm()}
        <div className={this.state.stripeError ? 'dn' : ''}>
          <StripePaymentWrapper
            customerEmail={this.props.sale.customer.email}
            onError={() => {
              this.setState({ stripeError: true });
            }}
          >
            <StripeCreditCardForm
              customer={this.props.sale.customer}
              isMobile={this.props.isMobile}
              onError={() => {
                this.setState({ stripeError: true });
              }}
              onReady={() => {
                this.setState({ stripeError: false });
              }}
              onSubmit={paymentMethodId => {
                try {
                  this.handleCreateStripeCreditCard(paymentMethodId);
                  pubWithSale(Event.SalePayment.ADD_MANUAL_PAYMENT, {
                    outcome: 'success',
                  });
                } catch {
                  pubWithSale(Event.SalePayment.ADD_MANUAL_PAYMENT, {
                    outcome: 'failure',
                  });
                }
              }}
            />
          </StripePaymentWrapper>
        </div>
      </div>
    );
  }

  renderIncompleteCreditCardForm() {
    return (
      <div className="ma3">
        <p className="pa2 bg-gray-9 red ba b--gray-9 col7 tc">
          Please manually enter the remaining credit card fields
        </p>
        {this.renderStripeCreditCardForm()}
      </div>
    );
  }

  renderAddCardTab() {
    const addedTemporaryCard =
      this.props.sale.payment_method &&
      !this.props.sale.customer.credit_cards.find(
        card => card.id === this.props.sale.payment_method.id,
      );

    return (
      <Fragment>
        {!addedTemporaryCard && !this.state.forceManualEntry && (
          <div>
            <div className="ma3">
              <ExpandableContent
                onExpand={() =>
                  pubWithSale(Event.SalePayment.EXPAND_MANUAL_PAYMENT_FORM)
                }
                expandText="Manually Add Card"
                allowCollapsing={false}
                buttonStyle={ButtonStyles.SECONDARY}
                isMobile={this.props.isMobile}
              >
                {this.renderStripeCreditCardForm()}
              </ExpandableContent>
            </div>
          </div>
        )}

        {addedTemporaryCard && (
          <div>
            <CreditCard
              isActive
              isRemovable
              className="ml3 mv2"
              fullName={this.props.sale.payment_method.full_name}
              lastDigits={this.props.sale.payment_method.last_digits}
              brand={this.props.sale.payment_method.brand}
              expirationDate={this.props.sale.payment_method.expiration_date}
              onRemove={this.handleRemoveTemporaryPaymentMethod}
            />
          </div>
        )}
      </Fragment>
    );
  }

  renderCardsOnFileTab() {
    // We are filtering out expired credit cards
    const creditCards = this.props.sale.customer.credit_cards.filter(
      creditCard => {
        const [expirationMonth, expirationYear] =
          creditCard.expiration_date.split('/');

        // Building a new Date object which represents the first day of the next
        // month after expiration. E.g. if a card expires on 04/2020, this Date
        // will point to 05/1/2020 at midnight.
        // prettier-ignore
        const expirationDate = new Date(
          expirationYear, parseInt(expirationMonth, 10) + 1,
          1, 0, 0, 0, 0
        );

        return expirationDate > new Date();
      },
    );

    return (
      <Fragment>
        {this.props.sale.customer.credit_cards.length === 0 && (
          <NoItemsMessage>No cards on file</NoItemsMessage>
        )}
        <div className="flex flex-wrap ma2">
          {creditCards.map(creditCard => (
            <CreditCard
              isRemovable
              key={creditCard.id}
              fullName={creditCard.full_name}
              lastDigits={creditCard.last_digits}
              brand={creditCard.brand}
              expirationDate={creditCard.expiration_date}
              onRemove={() => this.handleRemoveCreditCard(creditCard)}
              onClick={() => this.handleAddCreditCardToSale(creditCard)}
              isActive={
                this.props.sale.payment_method &&
                this.props.sale.payment_method.id === creditCard.id
              }
            />
          ))}
        </div>
      </Fragment>
    );
  }

  renderCashTab() {
    // if there are online items being shipped
    if (this.props.saleItemsBeingShipped.length > 0) {
      return (
        <NoItemsMessage>
          Cash payments are not accepted for orders made online.
        </NoItemsMessage>
      );
    }

    // if there is no cash float currently open for the day
    if (
      !this.props.cashFloat.starting_amount ||
      this.props.cashFloat.ending_amount
    ) {
      return (
        <NoItemsMessage>
          Please have your store lead enter a cash float for today.
        </NoItemsMessage>
      );
    }

    const isButtonDisabled =
      this.props.orderTotal === '$0' || !this.state.cashValue;
    const rightPane = this.props.sale.change_issued ? (
      <Fragment>
        <div className="mb3 gray-6 f5">Change Due</div>
        <div className="f2">
          {`$${(
            parseFloat(this.props.sale.cash_amount.replace(/[^\d.]/, '')) -
            parseFloat(this.props.orderTotal.replace(/[^\d.]/, ''))
          ).toFixed(2)}`}
        </div>
        <div className="mt3 gray-6 f5">{`Total Amount: ${this.props.orderTotal}`}</div>
      </Fragment>
    ) : (
      <Fragment>
        <div className="mb3 gray-6 f5">Total Cash (USD)</div>
        <div className="f2">{`$${this.state.cashValue || '0.00'}`}</div>
        <div className="mt3 gray-6 f5">{`Total Amount: ${this.props.orderTotal}`}</div>
        <Button
          onClick={this.handleApplyCash}
          className="w-100 mt3"
          disabled={isButtonDisabled}
          buttonStyle={ButtonStyles.SECONDARY}
        >
          Enter Amount
        </Button>
      </Fragment>
    );

    return (
      <div className="flex items-center pv2 ph3">
        <div className="mr7">
          <div className="pb2 f5 gray-6">Enter cash amount below</div>
          <NumberKeyPad onClickNumber={this.handleClickKeyPadNumber} />
        </div>
        <div
          className="flex flex-column items-center ml7"
          style={{ width: '200px' }}
        >
          {rightPane}
          {this.state.cashError && (
            <div className="mt1 red f6">{this.state.cashError}</div>
          )}
        </div>
      </div>
    );
  }

  renderExistingCreditTab() {
    if (
      this.props.sale.customer.credits_total === 0 &&
      this.props.sale.customer.is_employee == false
    ) {
      return <NoItemsMessage>No existing credit</NoItemsMessage>;
    }

    return (
      <Fragment>
        {this.props.sale.customer.credits_total > 0 && (
          <CreditToggle
            onClick={this.handleToggleCredit}
            isApplied={this.props.sale.apply_credits}
            availableCredit={this.props.sale.customer.credits_total}
            appliedCredit={
              this.props.creditAdjustment
                ? this.props.creditAdjustment.amount
                : '$0'
            }
            type={'credits'}
          />
        )}

        {this.props.sale.customer.is_employee && (
          <CreditToggle
            onClick={this.handleToggleRetailDiscount}
            isApplied={this.props.sale.apply_retail_discount}
            availableCredit={this.props.sale.customer.credits_total}
            appliedCredit={
              this.props.creditAdjustment
                ? this.props.creditAdjustment.amount
                : '$0'
            }
            discountItemsRemaining={
              this.props.sale.customer.is_retail_staff
                ? this.props.discountItemsRemainingRetail
                : this.props.discountItemsRemainingStaff
            }
            type={
              this.props.sale.customer.is_retail_staff
                ? 'retailDiscount'
                : 'hqDiscount'
            }
            disabled={
              this.props.discountItemsRemainingRetail === 0 &&
              this.props.discountItemsRemainingStaff === 0
            }
          />
        )}
      </Fragment>
    );
  }

  renderNewCreditTab() {
    if (!this.props.showCreditForms) {
      return <NoItemsMessage>You cannot apply new credit</NoItemsMessage>;
    }

    return (
      <CreditForm
        style={this.props.isMobile ? { width: '85%' } : {}}
        onSubmit={this.handleApplyNewCredit}
        possibleReasons={this.state.reasonsToGrantCredit}
      />
    );
  }

  renderFreeShippingTab() {
    if (!this.props.showCreditForms) {
      return <NoItemsMessage>You cannot grant free shipping</NoItemsMessage>;
    }

    return (
      <div className="ma3">
        <Button
          size="medium"
          className={this.props.isMobile ? 'col5' : 'col6'}
          onClick={this.handleGrantFreeShipping}
          disabled={this.state.freeShippingGranted}
          buttonStyle={ButtonStyles.SECONDARY}
        >
          Grant Free Shipping
        </Button>
      </div>
    );
  }

  renderGiftCardTab() {
    return (
      <DiscountCodeForm
        onSubmit={this.handleApplyDiscount}
        isMobile={this.props.isMobile}
      />
    );
  }

  shouldPromptForStripeTerminal() {
    return (
      this.props.saleStatus === Status.NO_PAYMENT &&
      this.props.location.pathname.includes('payment')
    );
  }

  handleMobileCTAClick = () => {
    const { storefrontId, saleNumber } = this.props.match.params;

    if (this.shouldPromptForStripeTerminal()) {
      this.props.history.push(
        `/storefronts/${storefrontId}/sales/${saleNumber}/stripe`,
      );
    }

    this.props.onContinue();
  };

  renderMobileCTA() {
    const stripeEnabled = this.shouldPromptForStripeTerminal();
    const isReadyForCompletion =
      this.props.saleStatus === Status.READY_FOR_COMPLETION;
    let text = isReadyForCompletion ? `Review Sale` : 'Add Payment Method';
    if (stripeEnabled) {
      text = `Charge ${this.props.orderTotal} via Stripe`;
    }

    return (
      <div className="absolute bottom-0 left-0 w-100 bg-white gray-5">
        <div className="w-100 h4 f4 fw5 lh-solid bt b--gray-9 flex items-center justify-between ph2">
          <span>Total:</span>
          <span>{this.props.orderTotal}</span>
        </div>
        <Button
          size="full-width"
          className="h6 f4"
          buttonStyle={ButtonStyles.SECONDARY}
          disabled={!isReadyForCompletion && !stripeEnabled}
          onClick={this.handleMobileCTAClick}
          rounded={false}
        >
          {text}
        </Button>
      </div>
    );
  }

  render() {
    const { params } = this.props.match;
    const paymentMethodTabs = [
      {
        title: 'Manually Add Card',
        value: 'add-card',
        isActive: this.isPaymentMethodTabSelected('add-card'),
      },
    ];

    if (
      this.props.sale.customer.credit_cards &&
      this.props.sale.customer.credit_cards.length > 0
    ) {
      paymentMethodTabs.push({
        title: 'Cards on File',
        value: 'cards',
        isActive: this.isPaymentMethodTabSelected('cards'),
      });
    }

    if (this.props.acceptsCash && !this.props.isMobile) {
      paymentMethodTabs.push({
        title: 'Cash',
        value: 'cash',
        isActive: this.isPaymentMethodTabSelected('cash'),
      });
    }

    return (
      <div>
        <TopBar
          color="blue-4"
          textColor="white"
          left={
            <Link
              to={`/storefronts/${params.storefrontId}/sales/${params.saleNumber}/product`}
              onClick={() => pubWithSale(Event.SalePayment.BACK_TO_SALE)}
              className="no-underline"
            >
              <IconWithLabel
                icon="arrow-left"
                iconClassName="f5 pr1"
                label="Add Product"
              />
            </Link>
          }
          center={!this.props.isMobile && 'Add Payment Method'}
        />

        <Scrollable>
          {this.state.stripeError && this.renderStripeErrorMessage()}

          <h3 className="normal gray-6 f4 mv3 ml3">
            Swipe a credit card or choose a payment method below.
          </h3>

          {this.state.forceManualEntry && this.renderIncompleteCreditCardForm()}

          <Tabs
            onClickTab={this.handleClickPaymentMethodTab}
            tabs={paymentMethodTabs}
            isMobile={this.props.isMobile}
          />

          <div className="mb3">
            {this.isPaymentMethodTabSelected('add-card') &&
              this.renderAddCardTab()}

            {this.isPaymentMethodTabSelected('cards') &&
              this.renderCardsOnFileTab()}

            {this.isPaymentMethodTabSelected('cash') && this.renderCashTab()}
          </div>

          <Tabs
            isMobile={this.props.isMobile}
            onClickTab={this.handleClickCreditsTab}
            tabs={[
              {
                title: 'Existing Credit',
                value: 'existing-credit',
                isActive: this.isCreditsTabSelected('existing-credit'),
              },
              {
                title: 'New Credit',
                value: 'new-credit',
                isActive: this.isCreditsTabSelected('new-credit'),
              },
              {
                title: 'Grant Free Shipping',
                value: 'free-shipping',
                isActive: this.isCreditsTabSelected('free-shipping'),
              },
              {
                title: 'Gift Card',
                value: 'gift-card',
                isActive: this.isCreditsTabSelected('gift-card'),
              },
            ]}
          />

          <div style={{ marginBottom: '100px' }}>
            {this.isCreditsTabSelected('existing-credit') &&
              this.renderExistingCreditTab()}

            {this.isCreditsTabSelected('new-credit') &&
              this.renderNewCreditTab()}

            {this.isCreditsTabSelected('free-shipping') &&
              this.renderFreeShippingTab()}

            {this.isCreditsTabSelected('gift-card') && this.renderGiftCardTab()}
          </div>
        </Scrollable>
        {this.props.isMobile && this.renderMobileCTA()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { saleNumber, storefrontId } = ownProps.match.params;
  const storefront = getStorefrontById(state.storefronts, storefrontId);

  return {
    orderTotal: state.orderPreview.total,
    showCreditForms: getCanAssociateApplyCreditToSale(state.associate),
    sale: getSaleByNumber(state.sales, ownProps.match.params.saleNumber),
    creditAdjustment: state.orderPreview.adjustments.find(
      adjustment => adjustment.label === 'Credit',
    ),
    acceptsCash: storefront && storefront.accepts_cash,
    cashFloat: state.cashFloats && state.cashFloats[0],
    discountItemsRemainingRetail:
      state.sales[ownProps.match.params.saleNumber].customer
        .discount_items_remaining_retail,
    discountItemsRemainingStaff:
      state.sales[ownProps.match.params.saleNumber].customer
        .discount_items_remaining_staff,
    saleItemsBeingShipped: getSaleItemsBeingShipped(state.sales, saleNumber),
    saleStatus: getSaleStatus(
      state.sales,
      saleNumber,
      ownProps.location.pathname,
      state.orderPreview.total,
    ),
    isMobile: state.ui.isMobile,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { saleNumber, storefrontId } = ownProps.match.params;

  return {
    removePaymentFromSale: () => dispatch(removePaymentFromSale(saleNumber)),
    toggleApplyCredit: () => dispatch(toggleApplyCredit(saleNumber)),
    toggleRetailDiscount: () => dispatch(toggleRetailDiscount(saleNumber)),
    applyNewCredit: (amount, reason) =>
      dispatch(applyNewCredit(saleNumber, amount, reason)),
    showErrorNotification: message =>
      dispatch(showNotification(NotificationTypes.ERROR, message)),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    applyCashToSale: amount => dispatch(applyCashToSale(saleNumber, amount)),
    addCreditCardToSale: creditCard =>
      dispatch(addCreditCardToSale(saleNumber, creditCard)),
    applyDiscountCode: (discountCode, customerEmail) =>
      dispatch(
        applyDiscountCode({
          discountCode,
          customerEmail,
          saleNumber,
        }),
      ),
    createCreditCard: ({ nonce, paymentType, customerEmail }) =>
      dispatch(
        createCreditCard({
          saleNumber,
          nonce,
          paymentType,
          customerEmail,
        }),
      ),
    disableCreditCard: card =>
      dispatch(disableCreditCard(card, ownProps.match.params.saleNumber)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    grantFreeShipping: () => dispatch(grantFreeShipping(saleNumber)),
    fetchCashFloats: () => dispatch(fetchCashFloats(storefrontId)),
    fetchOrderPreview: () => dispatch(fetchOrderPreview(saleNumber)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentToSale);
