import React, { Component } from 'react';
import { func, string, bool } from 'prop-types';
import Button, { ButtonStyles } from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import NumberFormat from 'react-number-format';
import getConstants from 'lib/constants';
import classNames from 'classnames';

export default class CreditCardForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    error: string,
    isMobile: bool,
  };

  static defaultProps = {
    initialFullName: '',
    initialNumber: '',
    initialExpirationDate: '',
  };

  state = {
    fullName: this.props.initialFullName,
    number: this.props.initialNumber,
    expirationDate: this.props.initialExpirationDate,
    securityCode: '',
    country: 'US',
    postalCode: '',
  };

  isReadyForSubmission() {
    return (
      this.state.fullName &&
      this.state.number &&
      this.state.expirationDate &&
      this.state.securityCode &&
      this.isPostalCodePresent()
    );
  }

  isPostalCodeRequired() {
    return this.state.country === 'US';
  }

  isPostalCodePresent() {
    if (this.isPostalCodeRequired() && !this.state.postalCode) {
      return false;
    }
    return true;
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props
      .onSubmit({
        fullName: this.state.fullName,
        number: this.state.number,
        expirationDate: this.state.expirationDate,
        securityCode: this.state.securityCode,
        country: this.state.country,
        postalCode: this.state.postalCode,
      })
      .then(() =>
        this.setState({
          fullName: '',
          number: '',
          expirationDate: '',
          securityCode: '',
          country: 'US',
          postalCode: '',
        }),
      );
  };

  renderFullNameInput() {
    return (
      <Input
        className="mr2"
        style={{ width: '13rem' }}
        value={this.state.fullName}
        placeholder="Enter Full Name"
        onChange={event => this.setState({ fullName: event.target.value })}
        id="fullName"
        label="Full Name"
      />
    );
  }

  renderNumberInput() {
    return (
      <Input
        style={{ width: '13rem' }}
        id="number"
        label="Credit Card Number"
        TextField={
          <NumberFormat
            name="number"
            value={this.state.number}
            format={'#### #### #### ####'}
            placeholder="0000 0000 9900 1234"
            className="ba b--gray-6 f4 h6 ph1 w-100"
            onChange={event => this.setState({ number: event.target.value })}
          />
        }
      />
    );
  }

  renderExpirationDateInput() {
    return (
      <Input
        className="mr2"
        style={{ width: '13rem' }}
        id="expirationDate"
        label="Expiration Date"
        TextField={
          <NumberFormat
            name="expirationDate"
            value={this.state.expirationDate}
            format={'##/##'}
            placeholder="MM/YY"
            className="ba b--gray-6 f4 h6 ph1 w-100"
            onChange={event =>
              this.setState({ expirationDate: event.target.value })
            }
          />
        }
      />
    );
  }

  renderSecurityCodeInput() {
    return (
      <Input
        style={{ width: '13rem' }}
        value={this.state.securityCode}
        placeholder="000"
        onChange={event => this.setState({ securityCode: event.target.value })}
        id="securityCode"
        label="Security Code"
      />
    );
  }

  renderCountryInput() {
    const countries = Object.entries(getConstants().billing_countries).map(
      ([countryCode, countryName]) => ({
        title: countryName,
        value: countryCode,
      }),
    );

    return (
      <Select
        label="Country"
        className="w-100 mr2"
        style={{ width: '13rem' }}
        value={this.state.country}
        onChange={event => this.setState({ country: event.target.value })}
        options={countries}
      />
    );
  }

  renderPostalCodeInput() {
    return (
      <Input
        style={{ width: '13rem' }}
        value={this.state.postalCode}
        placeholder="00000"
        onChange={event => this.setState({ postalCode: event.target.value })}
        id="postalCode"
        label="Postal Code"
      />
    );
  }

  renderSubmitButton() {
    const isReady = this.isReadyForSubmission();

    return (
      <Button
        className="mt2"
        disabled={!isReady}
        onClick={this.handleSubmit}
        buttonStyle={ButtonStyles.SECONDARY}
      >
        Add New Payment Method
      </Button>
    );
  }

  render() {
    return (
      <form
        className={classNames({
          col7: !this.props.isMobile,
          col5: this.props.isMobile,
        })}
      >
        {this.props.error && <p className="red">{this.props.error}</p>}
        <div className="flex justify-between mb1">
          {this.renderFullNameInput()}
          {this.renderNumberInput()}
        </div>
        <div className="flex justify-between mb1">
          {this.renderExpirationDateInput()}
          {this.renderSecurityCodeInput()}
        </div>
        <div className="flex justify-between mb1">
          {this.renderCountryInput()}
          {this.isPostalCodeRequired() && this.renderPostalCodeInput()}
        </div>
        {this.renderSubmitButton()}
      </form>
    );
  }
}
