import { loadStripeTerminal } from '@stripe/terminal-js';
import { isProduction } from 'lib/env';
import { post } from 'lib/network';
import { retailApiUrl } from 'lib/url';

// This file implements and interacts with the Stripe Terminal API
// Their documentation is super comprehensive and can be found here:
// https://stripe.com/docs/terminal/payments/setup-integration?terminal-sdk-platform=js#connection-token-client-side

let _terminal;
let connectPromise;

export async function getConnectedReader() {
  // We are maintaining a Promise here, which will remain unresolved until it is determined that
  // there is a) no reader to connect to or b) we have connected to a reader.
  if (connectPromise) {
    return connectPromise;
  }

  connectPromise = new Promise(async (resolve, reject) => {
    try {
      const terminal = await getTerminal();

      if (terminal.getConnectionStatus() !== 'connected') {
        let lastReader;
        try {
          lastReader = await connectToLastReader(terminal);

          if (!lastReader) {
            reject('could not connect to last reader');
          }
        } catch (e) {
          reject(e.message);
        }

        if (lastReader) {
          resolve(lastReader);
        } else {
          reject('could not connect to last reader');
        }
      } else {
        resolve(terminal.getConnectedReader());
      }
    } catch(e) {
      reject(e)
    }
  });

  return connectPromise;
}

let terminalPromise;
let failed = false;

export async function getTerminal() {
  if (!failed && terminalPromise) {
    return terminalPromise;
  }

  if (_terminal) {
    return _terminal;
  }

  terminalPromise = new Promise(async (resolve, reject) => {
    try {
      const StripeTerminal = await loadStripeTerminal();

      // https://stripe.com/docs/terminal/references/api/js-sdk#stripeterminal-create
      _terminal = StripeTerminal.create({
        onFetchConnectionToken: async () => {
          const { response, responseJson } = await post(
            retailApiUrl('stripe/create_connection_token'),
          );

          if (response.ok) {
            return responseJson.secret;
          }
        },
        onUnexpectedReaderDisconnect: () => {
          alert('disconnected from reader!');
        },
      });

      window.term = _terminal;
      failed = false

      resolve(_terminal);
    } catch(e) {
      failed = true
      reject(e)
    }
  });

  return terminalPromise;
}

// https://stripe.com/docs/terminal/references/api/js-sdk#discover-readers
export async function discoverReaders(terminal, config = {}) {
  if (!isProduction()) {
    // Defaults development environments to Stripe development location
    config.location = 'tml_Edq1egAI9vZIwT';
    // flip to false for developing with a hardware terminal
    config.simulated = true;
  }

  const discoverResult = await terminal.discoverReaders(config);

  if (discoverResult.error) {
    throw new Error(discoverResult.error);
  }

  return discoverResult.discoveredReaders;
}

// https://stripe.com/docs/terminal/references/api/js-sdk#connect-reader
export async function connectReader(terminal, reader) {
  const connectResult = await terminal.connectReader(reader);

  if (connectResult.error) {
    throw new Error(connectResult.error.message);
  }

  // We store the newly connected readers serial number in local storage so we can automatically
  // reconnect to it next the POS launches or looses the connection for any reason.
  localStorage.setItem(
    'lastConnectedReaderSerial',
    connectResult.reader.serial_number,
  );

  return connectResult.reader;
}

// https://stripe.com/docs/terminal/references/api/js-sdk#collect-payment-method
export async function collectPaymentMethod(terminal, clientSecret) {
  const result = await terminal.collectPaymentMethod(clientSecret);

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result.paymentIntent;
}

// https://stripe.com/docs/terminal/references/api/js-sdk#process-payment
export async function processPayment(terminal, paymentIntent) {
  const result = await terminal.processPayment(paymentIntent);

  if (result.error) {
    throw new Error(result.error.message);
  } else if (result.paymentIntent) {
    return paymentIntent;
  }
}

export async function connectToLastReader(terminal) {
  const discoveredReaders = await discoverReaders(terminal);
  const storedSerialNumber = localStorage.getItem('lastConnectedReaderSerial');
  const lastReader = discoveredReaders.find(
    r => r.serial_number === storedSerialNumber,
  );

  if (lastReader) {
    const connectedReader = await connectReader(terminal, lastReader);
    return connectedReader;
  }
}

// This function formats existing sale data into a shape that Stripe requires
// to render the sale details on the reader screen itself
// https://stripe.com/docs/terminal/references/api/js-sdk#set-reader-display
export function formatSaleForReaderDisplay({ saleItems, orderPreview }) {
  const displayPriceToCents = price =>
    Math.round(parseFloat(price.replace(',','').replace(/[^0-9.]/, '')) * 100);

  const lineItems = saleItems.reduce((memo, item) => {
    const existingLineItem = memo.find(i => i.description === item.title);

    if (existingLineItem) {
      existingLineItem.quantity++;
    } else {
      const displayPrice = orderPreview.line_item_prices.find(
        price => price.line_item_id === item.id,
      );

      if (displayPrice) {
        memo.push({
          description: item.title,
          quantity: 1,
          amount: displayPriceToCents(displayPrice.discounted_price),
        });
      }
    }

    return memo;
  }, []);

  return {
    type: 'cart',
    cart: {
      line_items: lineItems,
      tax: displayPriceToCents(
        orderPreview.adjustments.find(adj => adj.label === 'Tax')?.amount ||
          '$0',
      ),
      total: displayPriceToCents(orderPreview.total),
      currency: 'usd',
    },
  };
}
