export function isProduction() {
  return process.env.REACT_APP_ENV === 'production';
}

export function isDevelopment() {
  return process.env.REACT_APP_ENV === 'development';
}

export function areWebSocketsEnabled() {
  return [true, 'true'].includes(process.env.REACT_APP_WEB_SOCKETS_ENABLED);
}
