import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, func, string } from 'prop-types';
import Button, { ButtonStyles } from 'components/shared/Button';
import Checkbox from 'components/shared/Checkbox';
import { getIsLoading } from 'reducers/activeRequests';

import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

class CustomerDetailsForm extends Component {
  static propTypes = {
    ctaText: string,
    onFormSubmit: func.isRequired,
    initialEmail: string,
    initialFirstName: string,
    initialLastName: string,
    emailOptout: bool,
    isEmailEditable: bool,
    isEmailOptOutEditable: bool,
  };

  static defaultProps = {
    ctaText: 'CONTINUE',
    isEmailEditable: false,
    isEmailOptOutEditable: true,
  };

  state = {
    firstName: this.props.initialFirstName || '',
    lastName: this.props.initialLastName || '',
    email: this.props.initialEmail || '',
    emailOptout: false,
    phoneNumber: '',
    smsOptIn: false,
  };

  constructor(props) {
    super(props);

    this.emailInput = React.createRef();
    this.firstNameInput = React.createRef();
    this.lastNameInput = React.createRef();
    this.phoneNumberInput = React.createRef();
  }

  componentDidMount() {
    if (this.props.isEmailEditable) {
      this.emailInput.current.focus();
    } else {
      this.firstNameInput.current.focus();
    }
  }

  handleFormSubmit = event => {
    event.preventDefault();
    let customerAttributes = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone_number: this.state.phoneNumber,
      sms_opt_in: this.state.smsOptIn,
    };

    if (this.props.isEmailOptOutEditable) {
      customerAttributes['email_optout'] = this.state.emailOptout;
    }

    this.props.onFormSubmit(customerAttributes);
  };

  renderEmailInput() {
    return (
      <label className="db mb3">
        <span className="mb1">Email: </span>
        {this.props.isEmailEditable ? (
          <input
            autoCapitalize="off"
            autoCorrect="off"
            onChange={() =>
              this.setState({ email: this.emailInput.current.value })
            }
            className="h6 pl1 ba b--gray-9 input-reset br0 sans-serif"
            disabled={this.props.isLoading}
            placeholder="Enter your first name"
            ref={this.emailInput}
            type="email"
            value={this.state.email}
          />
        ) : (
          <span className="fw5">{this.state.email}</span>
        )}
      </label>
    );
  }

  renderFirstNameInput() {
    return (
      <label className="db mb3">
        <input
          autoCapitalize="off"
          autoCorrect="off"
          onChange={() =>
            this.setState({ firstName: this.firstNameInput.current.value })
          }
          className="w-100 h6 pl2 ba b--white input-reset sans-serif bg-gray-2 white white-placeholder"
          disabled={this.props.isLoading}
          placeholder="First name"
          ref={this.firstNameInput}
          type="text"
          value={this.state.firstName}
        />
      </label>
    );
  }

  renderLastNameInput() {
    return (
      <label className="db mb3">
        <input
          autoCapitalize="off"
          autoCorrect="off"
          onChange={() =>
            this.setState({ lastName: this.lastNameInput.current.value })
          }
          className="w-100 h6 pl2 ba b--white input-reset sans-serif bg-gray-2 white white-placeholder"
          disabled={this.props.isLoading}
          placeholder="Last name"
          ref={this.lastNameInput}
          type="text"
          value={this.state.lastName}
        />
      </label>
    );
  }

  renderPhoneNumberInput() {
    return (
      <label className="db mb3">
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry="US"
          countries={['US', 'GB', 'CA']}
          placeholder="Phone number"
          value={this.state.phoneNumber}
          className="customer-details-phone-input w-100 h6 pl2 ba b--white input-reset sans-serif bg-gray-2 white white-placeholder"
          onChange={number => {
            this.setState({ phoneNumber: number });
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.target.blur();
            }
          }}
        />
      </label>
    );
  }

  renderEmailOptoutInput() {
    return (
      <Checkbox
        id="email-optout"
        label="Sign up for emails and receive early access to product launches."
        className="lh-copy mb3"
        onChange={event =>
          this.setState({ emailOptout: !event.target.checked })
        }
        checked={!this.state.emailOptout}
      />
    );
  }

  renderSmsOptInInput() {
    return (
      <Checkbox
        id="sms-optin"
        label="I'd also like to receive text notifications to get early access on product launches, exclusive offers, and more"
        className="lh-copy mb3 sms-checkbox"
        onChange={event => this.setState({ smsOptIn: event.target.checked })}
        checked={this.state.smsOptIn}
      />
    );
  }

  renderOptInCopy() {
    return (
      <p className="lh-copy mt3 mb2 f7">
        By checking this box, you agree to receive recurring automated
        promotional and personalized marketing text messages (e.g. cart
        reminders) from Everlane at the cell number used when signing up.
        Consent is not a condition of any purchase. Reply HELP for help and STOP
        to cancel. Msg frequency varies. Msg &amp; data rates may apply. View{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://attnl.tv/t/Pwi"
        >
          Terms
        </a>{' '}
        &amp;{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://attnl.tv/p/Pwi"
        >
          Privacy.
        </a>
      </p>
    );
  }

  renderSubmitButton() {
    return (
      <div className="w-100 flex justify-center align-center mb4">
        <Button
          buttonStyle={ButtonStyles.QUATERNARY}
          className="br-pill fw5 tracked ph4 f5"
        >
          {this.props.isLoading ? 'LOADING' : this.props.ctaText}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <form onSubmit={this.handleFormSubmit} className="w-30">
        {this.renderEmailInput()}

        <fieldset className="pa0 bn">
          {this.renderFirstNameInput()}
          {this.renderLastNameInput()}
          {this.renderPhoneNumberInput()}

          {this.props.isEmailOptOutEditable && this.renderEmailOptoutInput()}
          {this.renderSmsOptInInput()}
          {this.renderOptInCopy()}
          {this.renderSubmitButton()}
        </fieldset>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoading: getIsLoading(state.activeRequests),
  };
}

export default connect(mapStateToProps)(CustomerDetailsForm);
